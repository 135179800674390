import React from 'react';
import notFoundImage from '../assets/images/404.jpg'; 

function NotFound() {
    const redirectToHome = () => {
      window.location.href = '/'; // Redirect to the home page
    };
  
    return (
      <div className="not-found-container">
        <img className="not-found-image" src={notFoundImage} alt="Not Found" />
        <h1>Are you lost ?</h1>
        <p>We can’t find the page you’re looking for.</p>
        <button className="not-found-button" onClick={redirectToHome}>Go to Home</button>
      </div>
    );
  }
  
  export default NotFound;